export const tools = [
    {
        id: "amphetamine",
        name: "Amphetamine",
        description: "Keep your Mac alive",
        type: "mac",
        tag: "Utility",
        tagcolor: "#B2F5EA4D",
        tagfontcolor: "#B2F5EA",
        bgcolor: "#234E52",
        image: "images/tools/amph.png",
        link: "https://apps.apple.com/us/app/amphetamine/id937984704?mt=12",
    },
    {
        id: "raycast",
        name: "Raycast",
        description: "Spotlight, but it's actually good",
        type: "mac",
        tag: "Utility",
        tagcolor: "#B2F5EA4D",
        tagfontcolor: "#B2F5EA",
        bgcolor: "#F88185",
        image: "images/tools/raycast.png",
        link: "https://www.raycast.com/",
    },
    {
        id: "vscode",
        name: "Visual Studio Code",
        description: "Code Editor",
        type: "mac",
        tag: "Development",
        tagcolor: "#80CFF84D",
        tagfontcolor: "#80CFF8",
        bgcolor: "#80CFF8",
        image: "images/tools/vscode.png",
        link: "https://code.visualstudio.com/",
    },
    {
        id: "postman",
        name: "Postman",
        description: "API platform for building and testing APIs",
        type: "mac",
        tag: "Development",
        tagcolor: "#FFA5004D",
        tagfontcolor: "#FFA500",
        bgcolor: "#FFA500",
        image: "images/tools/postman.png",
        link: "https://www.postman.com/",
    },
    {
        id: "iterm2",
        name: "iTerm2",
        description: "iTerm2 is a replacement for Terminal",
        type: "mac",
        tag: "Terminal",
        tagcolor: "#6CBC9C4D",
        tagfontcolor: "#6CBC9C",
        bgcolor: "#265A26",
        image: "images/tools/iterm2.png",
        link: "https://iterm2.com/",
    },
    {
        id: "shottr",
        name: "Shottr",
        description: "Better screenshot tool",
        type: "mac",
        tag: "Screenshot",
        tagcolor: "#6CBC9C4D",
        tagfontcolor: "#6CBC9C",
        bgcolor: "#265A26",
        image: "images/tools/shottr.png",
        link: "https://shottr.cc/",
    },
    {
        id: "sfsymbols",
        name: "SF Symbols",
        description: "Symbols supported by San Francisco Font",
        type: "mac",
        tag: "Screenshot",
        tagcolor: "#9999994D",
        tagfontcolor: "#999999",
        bgcolor: "#999999",
        image: "images/tools/sfsymbols.png",
        link: "https://developer.apple.com/sf-symbols/",
    },
    {
        id: "rectangle",
        name: "Rectangle",
        description: "Window manager",
        type: "mac",
        tag: "Utility",
        tagcolor: "#80CFF84D",
        tagfontcolor: "#80CFF8",
        bgcolor: "#80CFF8",
        image: "images/tools/rectangle.png",
        link: "https://rectangleapp.com/",
    },
    {
        id: "signal",
        name: "Signal",
        description: "Secure Messaging",
        type: "ios",
        tag: "Messaging",
        tagcolor: "#096EA34D",
        tagfontcolor: "#096EA3",
        bgcolor: "#096EA3",
        image: "images/tools/signal.png",
        link: "https://apps.apple.com/us/app/signal-private-messenger/id874139669",
    },
    {
        id: "scriptable",
        name: "Scriptable",
        description: "Script your own widgets",
        type: "ios",
        tag: "Code",
        tagcolor: "#096EA34D",
        tagfontcolor: "#096EA3",
        bgcolor: "#096EA3",
        image: "images/tools/scriptable.png",
        link: "https://scriptable.app/",
    },
]